<template>
  <workspace v-if="dataHandler"
             v-bind:customer-id="customerId"
             v-bind:data-handler="dataHandler"/>
  <div v-else>
    ...loading...
  </div>
</template>

<script>
import customers from '../config/customers';
import Workspace from '@/components/WorkSpace';

export default {
  name: 'Home',
  components: {
    Workspace
  },
  props: {
    customerId: {
      type: String,
    }
  },
  data() {
    return {
      dataHandler: null,
    }
  },
  async created() {
    /**
     * Dynamically load customer workspace class.
     */
    if(customers[this.customerId].workspace) {
      const { default: Workspace } = await import("@/classes/custom/" + customers[this.customerId].workspace + ".js");
      this.dataHandler = new Workspace();
    } else {
      console.error('Workspace-class is not defined in customer config');
    }
  },
}
</script>
