<template>
  <p>
    <strong>{{workspaceData?.template.name}}</strong>
  </p>
  <p>
    <span><strong>{{workspaceData?.stocks[0].name}}</strong> | </span>
    <span>{{workspaceData?.stocks[0].isin}}</span>
  </p>
  <p>
    <span>{{workspaceData?.dates[0]}}</span>
  </p>
</template>

<script>

export default {
  name: 'OnvistaFeedback',
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
  },
}
</script>