<template>
  <input type="file" @change="loadDynamicSignals" style="background-color: white" width="300px">
</template>

<script>

export default {
  name: 'CodevertiseSearch',
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
  },
  methods: {
    loadDynamicSignals(event) {
      // load file content
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        // parse as JSON
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          // set dynamic_signals in workspace
          this.dataHandler.setDynamicSignals(JSON.parse(fileReader.result));
        });
        fileReader.readAsText(file);
        //TODO: ipmlement error callbacks and show messages if json parsing or loading fails
      }
    },
  },
}
</script>