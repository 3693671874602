<template>
  <div class="tooltip">
    <span>{{ description }} </span><span v-if="value">({{ value }})</span>
  </div>
</template>

<script>

export default {
  name: "Tooltip",
  props: {
    description: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>

.tooltip {
  position: absolute;
  font-size: rem(14px);
  display: block;
  color: $text-color;
  font-weight: 400;
  padding: rem(15px);
  z-index: 101;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
</style>