<template>
  <div class="toggle" :class="cssClass">
    <span class="toggle__label">{{label}}</span>
    <input
        class="toggle__input"
        type="checkbox"
        name="toggle"
        id="toggle"
        @change="emitToggle($event)"
    >
    <label for="toggle"></label>
  </div>
</template>

<script>
export default {
  name: "toggle",
  emits: ['toggle-filter'],
  props: {
    // icon name, have a look into `/img/icons.svg`
    // to find a svg icon id to fill in here
    label: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    emitToggle(event) {
      this.$emit('toggle-filter', event.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin inactiveMixin {
  content: "";
  position: absolute;
  display: block;
}

@mixin beforeAnimation {
  transition: .2s cubic-bezier(.24, 0, .5, 1);
}

@mixin afterAnimation {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
  transition: .35s cubic-bezier(.54, 1.60, .5, 1);
}

.toggle {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-left: rem(20px);

  &__label {
    white-space: nowrap;
    font-size: rem(15px);
    opacity: 0.8;
    margin-right: rem(10px);
  }

  &__input {
    opacity: 0;
    z-index: -1;
    position: absolute;

    &:checked {

      & + label:before {
        background: var(--bg-dark-blue); // Active Color
        transition: width .2s cubic-bezier(0, 0, 0, .1);
      }

      & + label:after {
        left: 50px - 30px;
      }
    }

    & + label {
      position: relative;
      display: inline-block;
      user-select: none;
      transition: .4s ease;
      height: 30px;
      width: 50px;
      border: 1px solid #e4e4e4;
      border-radius: 60px;

      &:before {
        @include inactiveMixin;
        @include beforeAnimation;
        height: 30px;
        width: 50px;
        top: 0;
        left: 0;
        border-radius: 30px;
      }

      &:after {
        @include inactiveMixin;
        @include afterAnimation;
        background: whitesmoke;
        height: 28px;
        width: 28px;
        top: 1px;
        left: 0;
        border-radius: 60px;
      }
    }
  }
}
</style>