<template>
  <teleport to="#footer">
    <transition-group ref="list" name="toast-messages" tag="ul" class="toast-messages" v-on:enter="enter">
      <li
          v-for="(message, index) in list"
          v-bind:key="index"
          class="toast-messages__item"
      >
        <section
            class="toast-message"
            v-bind:class="{
             '-info': message.type === 'info',
             '-warning': message.type === 'warning',
             '-error': message.type === 'error',
           }"
        >
          <div class="toast-message__header">
            <h3 class="toast-message__headline">
              <Icon v-bind:name="iconName(message.type)" css-class="-toast" />
              <span>{{ message.type.charAt(0).toUpperCase() + message.type.slice(1)}}</span>
            </h3>
            <button class="button" v-on:click="remove(index)">
              <Icon name="cancel" />
            </button>
          </div>
          <div class="toast-message__body">
            <p>{{ message.text }}</p>
          </div>
        </section>
      </li>
    </transition-group>
  </teleport>
</template>

<script>
import Icon from "@/components/generic/Icon";

export default {
  name: 'ToastMessages',
  emits:['remove'],
  components: {Icon},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: Number,
      default: 1000,
    }
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    enter(el) {
      const index = [...this.$refs.list.$el.childNodes].findIndex(item => item === el);
      if (this.list[index].type === 'warning' || this.list[index].type === 'info') {
        setTimeout(() => {
          this.remove(index);
        }, this.duration);
      }
    },
    remove(index) {
      this.$emit('remove', this.list[index]);
    },
    iconName(messageType) {
      switch (messageType) {
        case 'info':
          return 'toast-info';
        case 'error':
          return 'toast-error';
        case 'warning':
          return 'toast-warning';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.toast-messages {
  position: absolute;
  bottom: 100%;
  right: 5%;
  list-style: none;

  &__item {
    margin-bottom: rem(15px);
    color: $text-color;
  }
}

.toast-message {
  min-width: rem(200px);
  border-radius: 5px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 10px rem(20px) rem(10px) rgba(0,0,0,0.15);
  border: 2px solid var(--border-light-2);
  padding: rem(15px) rem(20px);

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20px);

    .-error & {
      color: #FF3320;
    }

    .-warning & {
      color: #63A6E6;
    }

    .-info & {
      color: #4DC773;
    }
  }

  &__headline {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__body {
    font-size: rem(14px);
  }
}

.toast-messages-enter-active {
  transition: transform 1s ease;
}
.toast-messages-leave-active {
  transition: transform 0.5s ease;
}
.toast-messages-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.toast-messages-leave-to {
  opacity: 0;
  transform: translateY(30px);
}


</style>