<template>
  <input
      class="input"
      type="text"
      list="stocks"
      ref="stock"
      v-model="searchInputValue"
      v-bind:placeholder="stockPlaceholder"
      v-on:change="addStockToList"
      v-on:keyup="autocompleteSearch"
  >
  <datalist id="stocks">
    <option
        v-for="(stock, index) in stockList"
        v-bind:key="index"
    >
      {{ stock?.name }} | {{ stock?.isin }}
    </option>
  </datalist>
  <input
      class="input"
      type="date"
      list="dates"
      ref="date"
      :value="workspaceData?.dates[0]"
      v-on:change="addDateToList"
  >
  <datalist id="dates">
    <option
        v-for="(date, index) in workspaceData?.dates"
        v-bind:key="index"
    >
      {{ date }}
    </option>
  </datalist>
  <button class="button" v-on:click.prevent="simpleDebounce(requestDate, 500)">
    <Icon name="date-request" css-class="-date-request" />
  </button>
</template>

<script>
import simpleDebounce from '@/classes/debounce-mixin';
import Icon from '@/components/generic/Icon';

export default {
  name: 'OnvistaSearch',
  mixins: [simpleDebounce],
  components: {
    Icon,
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      searchInputValue: '',
      suggestionList: [],
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
    stockList() {
      return this.suggestionList?.length ? this.suggestionList : this.dataHandler.getData()?.stocks;
    },
    stockPlaceholder() {
      return `${this.workspaceData.stocks[0]?.name? this.workspaceData.stocks[0]?.name : 'Name' }` + ' | ' + `${this.workspaceData.stocks[0]?.isin? this.workspaceData.stocks[0]?.isin : 'ISIN' }`
    }
  },
  watch: {
    stockPlaceholder: function (newVal, oldVal) {
      if(newVal !== oldVal) {
        this.searchInputValue = '';
      }
    }
  },
  methods: {
    addStockToList(event) {
      if (event.target.value.indexOf('|') !== -1) {
        const stockInfo = event.target.value.split('|');
        this.dataHandler.addStockToList(stockInfo[0]?.trim(), stockInfo[1]?.trim());
        this.suggestionList = [];
      }
    },
    addDateToList(event) {
      const date = event.target.value;
      this.simpleDebounce(() => {
        this.dataHandler.addDateToList(date);
      }, 1500);
    },
    async autocompleteSearch(event) {
      if (event.target.value.indexOf('|') === -1) {
        this.simpleDebounce(async () => {
          await this.dataHandler.getStockList(event.target.value).then((data) => {
            this.suggestionList = data;
          });
        }, 500)
      }
    },
    requestDate() {
      this.dataHandler.requestDate();
    },
  },
}
</script>

<style lang="scss" scoped>

input {
  opacity: 0.8;
  flex-basis: rem(350px);
  margin-right: rem(15px);
  margin-bottom: rem(5px);
  margin-top: rem(5px);

  &:nth-of-type(2) {
    flex-basis: rem(170px);
  }
}

input[type="text"] {
  &::-webkit-calendar-picker-indicator {
    display: none;
    opacity: 0;
  }
}

</style>