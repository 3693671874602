<template>
  <teleport to="#navigation-feedback-button">
    <button @click="toggleFeedback">
      <span>Feedback</span>
      <Icon name="speech-bubble" />
    </button>
  </teleport>
  <Modal
      v-bind:show-modal="modalOpen"
      @toggle-modal="toggleFeedback"
      @submit-click="submitFeedback"
      header-text="Feedback geben"
      button-text="Feedback senden"
      icon-id="paper-plane"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <form class="feedback" id="feedback-form" name="feedback-form">
        <div class="feedback__header">
          <!-- dynamic components loader -->
          <component
              v-if="customers[customerId].feedback.feedbackModule"
              v-bind:customer-id="customerId"
              v-bind:data-handler="dataHandler"
              v-bind:is="customers[customerId].feedback.feedbackModule"
          />
        </div>
        <label class="label">Beispieltext</label>
        <div class="feedback__example">
          <div v-html="workspaceData?.example_text_with_replacement" />
        </div>
        <label class="label">Feedbacktext</label>
        <textarea class="input feedback__input" name="feedback" />
      </form>
    </template>
  </Modal>
</template>

<script>
import customers from '@/config/customers';
import Modal from "@/components/generic/Modal";
import Icon from '@/components/generic/Icon';
import DemoFeedback from './custom/feedback/DemoFeedback';
import OnvistaFeedback from './custom/feedback/OnvistaFeedback';

export default {
  name: "Feedback",
  components: {
    Modal,
    Icon,
    DemoFeedback,
    OnvistaFeedback,
  },
  props: {
    customerId: {
      type: String,
    },
    dataHandler: {
      type: Object,
    },
  },
  data() {
    return {
      customers,
      modalOpen: false,
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
    requiredFields() {
      const reqFields = this.customers[this.customerId].feedback.requiredFields;
      let valid = false;
      if(!reqFields) {
        return true;
      }
      valid = reqFields.every( field => this.workspaceData[field].length > 0)
      return valid;
    }
  },
  methods: {
    toggleFeedback() {
      if (this.requiredFields) {
        this.modalOpen = !this.modalOpen;
      } else {
        this.dataHandler.feedbackError();
      }
    },
    submitFeedback() {
      const form = document.querySelector('#feedback-form');
      const formData = new FormData(form);
      this.dataHandler.submitFeedback(formData);
      this.modalOpen = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback {
  display: flex;
  flex-direction: column;

  &__header {
    background: #F7F9FC;
    border-radius: 5px;
    padding: rem(10px) rem(15px);
    margin-bottom: rem(20px);
    line-height: 2;

    strong {
      font-weight: 500;
    }
  }

  &__example {
    background: #F7F9FC;
    border-radius: 5px;
    max-height: rem(150px);
    overflow-y: scroll;
    padding: rem(10px) rem(15px);
    margin-bottom: rem(20px);
  }

  &__input {
    height: rem(120px);
    padding: rem(15px);
  }
}

</style>