<template>
  <div class="workspace">
    <template-navigation
        @save="saveAs"
        @file="openTemplate"
        @new-template="newTemplate"
    />
    <Sidebar v-bind:allow-toggle="true">
      <template v-slot:header>
        <SignalSearch
            v-if="customers[customerId].signalSearch"
            @signal-search="signalSearch"
        />
      </template>
      <template v-slot:body>
        <tree-view
            v-bind:items="workspaceData?.signals"
            @showTooltip="showTooltip"
            @closeTooltip="tooltipShown = false"
            @dragStartSignal="tooltipShown = false"
        />
      </template>
      <template v-slot:footer>
        <router-link
            v-if="module === 'synonym-list-widget' || module === 'synonym-alternative-widget'"
            class="button workspace__router-button"
            v-bind:to="{name: 'Organizer'}"
        >
          <Icon name="layout" />
          <span>Layout</span>
        </router-link>
        <router-link
            v-else
            class="button workspace__router-button"
            v-bind:to="{name: 'SynonymList'}"
        >
          <Icon name="synonym" />
          <span>Synonyme</span>
        </router-link>
      </template>
    </Sidebar>
    <div class="workspace__main" ref="workspace-main" id="workspace">
      <section class="workspace__top">
        <title-editor
            v-bind:value="title"
            @edit-title="editTitle"
        />
        <main-search
            v-bind:customer-id="customerId"
            v-bind:data-handler="dataHandler"
            v-show="showConfig"
        />
        <div class="workspace__settings" id="workspaceSettings" v-show="showConfig">
          <router-link
              class="button edit-button -toggle"
              v-bind:to="{name: 'Organizer'}"
              v-bind:class="{'-active': module === 'layout-list-widget'}"
          >
            <Icon name="organizer" css-class="-view-toggle" />
          </router-link>
          <router-link
              class="button edit-button -toggle"
              v-bind:to="{name: 'Writer'}"
              v-bind:class="{'-active': module === 'writer-widget'}"
          >
            <Icon name="writer" css-class="-view-toggle" />
          </router-link>
          <span style="margin-left:10px;">(Beta)</span>
          <Toggle
              v-show="showToggle"
              label="Nur Aktive anzeigen:"
              @toggle-filter="toggleFilter"
          />
        </div>
      </section>
      <section class="workspace__body">
        <router-view
            v-bind:router-data="routerData"
            v-bind:active-ids="activeIds"
            v-bind:data-handler="dataHandler"
            v-bind:active-filter-toggled="activeFilterToggled"
        />
      </section>
    </div>
    <Sidebar
        v-if="showConfig"
        v-bind:allow-drag="true"
        v-bind:sidebar-right="true"
    >
      <template v-slot:body>
        <TextOutput
            v-bind:text-without-replacement="workspaceData?.example_text_without_replacement"
            v-bind:text-with-replacement="workspaceData?.example_text_with_replacement"
            @change-seed="changeSeed"
        />
      </template>
    </Sidebar>
  </div>
  <Feedback
      v-bind:customer-id="customerId"
      v-bind:data-handler="dataHandler"
  />
  <toast-message
      v-bind:list="toastMsgList"
      v-bind:duration="3000"
      v-on:remove="removeToastMsg"
  />
  <teleport to="body">
    <Tooltip
        v-show="tooltipShown"
        v-bind:description="tooltipDesc"
        v-bind:value="tooltipValue"
    />
  </teleport>
</template>

<script>
import customers from '@/config/customers';
import TreeView from '@/components/TreeView';
import SignalSearch from '@/components/SignalSearch';
import Icon from '@/components/generic/Icon';
import Toggle from '@/components/Toggle';
import TitleEditor from '@/components/TitleEditor';
import TemplateNavigation from "@/components/TemplateNavigation";
import Tooltip from "@/components/generic/Tooltip";
import ToastMessage from '@/components/generic/ToastMessages';
import MainSearch from './MainSearch';
import Feedback from './Feedback';
import Sidebar from '@/components/layout/Sidebar';
import TextOutput from '@/components/TextOutput';


export default {
  name: 'Workspace',
  components: {
    MainSearch,
    Feedback,
    ToastMessage,
    Tooltip,
    TemplateNavigation,
    TreeView,
    Icon,
    Toggle,
    TitleEditor,
    SignalSearch,
    Sidebar,
    TextOutput,
  },
  props: {
    customerId: {
      type: String,
    },
    synonymId: {
      type: String,
    },
    sectionId: {
      type: String,
    },
    dataHandler: {
      type: Object,
    },
  },
  data() {
    return {
      customers,
      activeFilterToggled: false,
      tooltipShown: false,
      tooltipDesc: '',
      tooltipValue: '',
      toastMsgList: [],
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
    showConfig() {
      return this.module !== 'text-overview' && this.module !== 'settings' && this.module !== 'templates';
    },
    showToggle() {
      return this.module !== 'synonym-list-widget' && this.module !== 'writer-widget';
    },
    module() {
      switch (this.$route.name) {
        case 'Writer':
          return 'writer-widget';
        case 'Organizer':
          return 'layout-list-widget'
        case 'SynonymList':
          return 'synonym-list-widget';
        case 'Synonym':
          return 'synonym-alternative-widget';
        case 'TextOverview':
          return 'text-overview';
        case 'Templates':
          return 'templates';
        case 'Settings':
          return 'settings';
        case 'Section':
          return 'section-alternative-widget';
        default: return null;
      }
    },
    routerData() {
      switch (this.$route.name) {
        case 'Writer':
          return this.dataHandler.getData().template.layouts;
        case 'Organizer':
          return this.dataHandler.getData().template.layouts;
        case 'SynonymList':
          return this.dataHandler.getData().template.synonyms;
        case 'Synonym':
          return this.dataHandler.getSynonymById(this.synonymId);
        case 'TextOverview':
          return this.dataHandler.getData().template.sections;
        case 'Settings':
          return this.dataHandler.getSettings();
        case 'Section':
          return this.dataHandler.getSectionAlternativesById(this.sectionId);
        default: return null;
      }
    },
    activeIds() {
      if (this.$route.name === 'SynonymList') {
        return;
      }
      if (this.$route.name === 'Synonym') {
        return this.dataHandler.getActiveSynonymReplacements();
      }
      if (this.$route.name === 'Writer') {
        return this.dataHandler.getActiveLayouts();
      }
      if (this.$route.name === 'Organizer') {
        return this.dataHandler.getActiveLayouts();
      }
      if (this.$route.name === 'Section') {
        return this.dataHandler.getActiveSectionAlternatives();
      } else {
        return null;
      }
    },
    title() {
      if (this.sectionId) {
        return this.dataHandler?.getSectionById(this.sectionId)?.name
      }
      return this.dataHandler?.getData().template.name;
    },
  },
  methods: {
    pushToastMsg(type, text) {
      if (type && text) {
        this.toastMsgList.push({type, text});
      } else {
        this.toastMsgList.push({type: 'error', text: 'Missing error type and/or text'});
      }
    },
    removeToastMsg(el) {
      const index = this.toastMsgList.findIndex(item => item === el);
      this.toastMsgList.splice(index, 1);
    },
    editTitle(value) {
      if (this.sectionId) {
        this.dataHandler.editSectionName(value, this.sectionId)
      } else {
        this.dataHandler.editTemplateName(value);
      }
    },
    toggleFilter(filterValue) {
      this.activeFilterToggled = filterValue;
    },
    changeSeed() {
      if (this.dataHandler.getRenderAllowance()) {
        this.dataHandler.changeSeed();
      } else {
        this.pushToastMsg('warning', 'Bitte wählen Sie eine Aktie und ein Datum!');
      }
    },
    saveAs() {
      let a = document.createElement("a");
      let file = new Blob([this.dataHandler.getDataAsJSON()], {type: 'application/json'});
      a.href = URL.createObjectURL(file);
      a.download = this.workspaceData?.template.name + ".json";
      a.click();
    },
    openTemplate(data) {
      this.dataHandler.openTemplate(data);
      this.$router.push({name: 'Organizer'});
      this.pushToastMsg('info', 'Story Datei geöffnet');
    },
    newTemplate() {
      this.dataHandler.newTemplate();
      this.$router.push({name: 'Organizer'});
    },
    showTooltip(event, desc, val) {
      const tooltip = document.querySelector('.tooltip');
      let x = event.clientX,
          y = event.clientY;
      this.tooltipDesc = desc;
      this.tooltipValue = val;
      tooltip.style.top = (y + 10) + 'px';
      tooltip.style.left = (x + 40) + 'px';
      this.tooltipShown = true;
    },
    signalSearch(signalArray) {
      this.dataHandler.signalSearch(signalArray);
    },
  },
  mounted() {
    this.dataHandler.addEventListener('toast-msg',(event) => {
      this.toastMsgList.push(event.message);
    }, false);
    window.onbeforeunload = function() { return "Your work will be lost."; };
    this.dataHandler.getInitalSignals();
  },
  beforeUnmount() {
    this.dataHandler.removeEventListener('toast-msg',(event) => {
      this.toastMsgList.push(event.message);
    }, false);
  },
}
</script>

<style lang="scss" scoped>
.workspace {
  height: calc(100vh - 60px);
  display: flex;
  font-size: rem(15px);

  &__main {
    width: 100%;
    background-color: var(--bg-light-1);
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__router-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    svg {
      height: rem(56px);
      width: rem(120px);
      margin-bottom: rem(20px);
    }
  }

  &__top {
    font-size: rem(20px);
    padding: rem(30px) rem(50px);
    border-bottom: 2px solid var(--border-light-1);
  }

  &__settings {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: rem(20px);
  }

  &__body {
    margin: rem(30px) rem(25px) rem(30px) rem(50px);
    padding-right: rem(15px);
    overflow-y: scroll;
    flex-grow: 1;

    &::-webkit-scrollbar-thumb {
      background-color: var(--bg-dark-2);
    }
  }
}
</style>