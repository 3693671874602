<template>
  <p>
    <strong>{{workspaceData?.template.name}}</strong>
  </p>
</template>

<script>

export default {
  name: 'DemoFeedback',
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
  },
}
</script>