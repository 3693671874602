<template>
  <div
      class="signal-search"
      data-signal-drop
      v-on:drop="onDropSignalSearch($event)"
      v-on:dragenter.prevent="dragEnterStyle($event)"
      v-on:dragover.prevent="dragEnterStyle($event)"
      v-on:dragleave.prevent="dragLeaveStyle($event)"
  >
    <div class="signal-search__dropzone">
      <span v-if="signals.length < 1">
        Signale ins Feld ziehen
      </span>
      <span
          class="signal-search__signal"
          v-for="(selector, index) in signals"
          v-bind:key="index"
          v-bind:class="{'not' : selector.startsWith('not.')}"
      >
          <button
              v-on:click="toggleSignal(selector)"
          >
            {{selector}}
          </button>
          <button v-on:click="removeSignal(selector)">
            <Icon name="cancel" css-class="signal-search__signal-icon" />
          </button>
      </span>
    </div>
    <button class="button signal-search__button" @click="signalSearch">
      <Icon name="arrow-right" />
    </button>
  </div>
</template>

<script>
import Icon from '@/components/generic/Icon';
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'SignalSearch',
  emits: ['signal-search'],
  mixins: [DragDrop],
  components: {
    Icon,
  },
  data() {
    return {
      signals: [],
    }
  },
  methods: {
    toggleSignal(signal) {
      const pos = this.signals.indexOf(signal);
      if (signal.startsWith('not.')) {
        this.signals[pos] = signal.replace('not.', '');
      } else {
        this.signals[pos] = 'not.' + signal;
      }
    },
    removeSignal(signal) {
      const pos = this.signals.indexOf(signal);
      this.signals.splice(pos, 1);
    },
    signalSearch() {
      this.$emit('signal-search', this.signals)
    }
  },
}
</script>

<style scoped lang="scss">

.signal-search {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding: rem(10px);
  background-color: var(--bg-light-1);
  border: 1px dashed var(--bg-light-blue);
  border-radius: rem(5px);

  &.dragover {
    border-color: var(--border-light-blue);
  }

  &__dropzone {
    width: 100%;
    margin-right: rem(10px);
    overflow-x: auto;
  }

  &__button {
    width: rem(40px);
    height: rem(40px);
    align-self: flex-end;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: #86C5FF;
  }

  &__signal {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: rem(12px);
    background-color: var(--bg-light-blue);
    border-radius: rem(15px);
    padding: rem(6px) rem(10px);
    margin: rem(2px);
    user-select: none;

    &.not {
      background-color: var(--bg-light-red);
    }

    button {
      font-size: rem(12px);
      line-height: 0;

      &:last-of-type {
        margin-left: rem(6px);
      }
    }
  }

  &__signal-icon {
    height: rem(10px);
  }
}

</style>