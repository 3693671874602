<template>
  <div class="text-output">
    <div hidden id="spinner" />
    <div class="text-output__header">
      <p class="text-output__headline">
        <button class="button text-output__switch" v-bind:class="{'-debug': outputDebugView}" @click="toggleOutputView()">
          <Icon name="exchange" />
        </button>
        <span v-if="!outputDebugView" >
              <span>Preview</span>
              <span class="text-output__count"  v-if="strippedText">
                  <span>
                    ({{strippedText.match(/[^\s]+/g).length}} Wörter
                  </span>
                |
                <span>
                    {{strippedText.length}} Zeichen)
                </span>
              </span>
            </span>
        <span v-else>Story</span>
      </p>
      <button class="button text-output__refresh" v-on:click="simpleDebounce(changeSeed, 500)">
        <Icon name="refresh" />
        <span>neu generieren</span>
      </button>
    </div>
    <div class="text-output__body -input" v-show="outputDebugView"  v-html="textWithoutReplacement" />
    <div class="text-output__body -output" v-show="!outputDebugView" v-html="textWithReplacement" />
  </div>
</template>

<script>
import Icon from '@/components/generic/Icon';
import simpleDebounce from "@/classes/debounce-mixin";
export default {
  name: "TextOutput",
  emits: ['change-seed'],
  mixins: [simpleDebounce],
  components: {
    Icon,
  },
  props: {
    textWithoutReplacement: {
      type: String,
      required: false,
    },
    textWithReplacement: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      outputDebugView: false,
    }
  },
  computed: {
    strippedText() {
      if (this.textWithReplacement) {
        return this.textWithReplacement.replace(/(<([^>]+)>)/gi, "").trim();
      }
      return '';
    },
  },
  methods: {
    toggleOutputView() {
      this.outputDebugView = !this.outputDebugView;
    },
    changeSeed() {
      this.$emit('change-seed');
    },
  }
}
</script>

<style lang="scss" scoped>

.text-output {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: rem(15px);
  border: 2px solid var(--border-light-2);
  border-radius: 5px;
  background-color: #ffffff;
  position: relative;
  margin-left: rem(10px);

  &__header {
    padding: rem(10px);
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid var(--border-light-2);
  }

  &__headline {
    font-weight: 500;
    margin-right: rem(10px);
  }

  &__switch {
    margin-right: rem(10px);
    color: #438ACC;

    &.-debug {
      transform: scale(-1, 1);
    }
  }

  &__count {
    font-weight: 400;
  }

  &__refresh {
    margin-top: rem(15px);
    margin-left: auto;
    color: #438ACC;
    font-weight: 500;
    font-size: rem(14px);
  }

  &__body {
    width: 100%;
    height: 100%;
    line-height: 1.8;
    padding: rem(10px);
    overflow-y: scroll;
  }
}

#spinner:not([hidden]) {
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#spinner::after {
  content: "";
  width: 80px;
  height: 80px;
  border: 2px solid grey;
  border-top: 3px solid #f25a41;
  border-radius: 100%;
  will-change: transform;
  animation: spin 1s infinite linear
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>