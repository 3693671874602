<template>
  <section class="tree-view">
    <ul class="tree-view__list">
      <li class="tree-view__list-item"
          v-bind:class="{'tree-view__list-item--no-child': !item.children || !item.children.length}"
          v-for="(item, index) in items"
          v-bind:key="index"
      >
        <div
            class="tree-view__item"
            v-bind:class="{ 'active': item.state === 'active' }"
        >
          <button class="tree-view__button"
                  v-show="item.children && item.children.length"
                  v-on:click="isOpen[index] = !isOpen[index]">
            <Icon name="minus" v-if="isOpen[index]" />
            <Icon name="plus" v-else />
          </button>
          <label class="tree-view__item-label" @mouseover="showTooltip($event, item.desc, item.value)" @mouseleave="closeTooltip">
            <span
                class="draggable"
                draggable="true"
                v-on:dragstart="startDrag($event, item.id)"
                v-on:dragend="endDrag($event)"
                v-on:mousedown="styleDrag($event)"
                v-on:mouseup="styleDrag($event)"
            >
              {{ item.id.split('.').slice(-1).pop() }}
            </span>
          </label>
        </div>
        <tree-view class="tree-view__list--child"
                   v-show="isOpen[index]"
                   v-if="item.children && item.children.length"
                   v-bind:items="item.children"
                   @showTooltip="showTooltip"
                   @closeTooltip="closeTooltip"
        />
      </li>
    </ul>
  </section>
</template>

<script>
import Icon from "@/components/generic/Icon";

export default {
  name: 'TreeView',
  components: {Icon},
  data() {
    return {
      isOpen: [],
      dragStyle: false,
    }
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    startDrag(event, id) {
      event.target.classList.add('dragging');
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copyMove';
      event.dataTransfer.setData('signal', id);
      this.$emit('dragStartSignal')
    },
    endDrag(event) {
      event.target.classList.remove('dragging');
    },
    styleDrag(event) {
      if (!this.dragStyle) {
        event.target.classList.add('omw');
      }
    },
    showTooltip(event, desc, val) {
      this.$emit('showTooltip', event, desc, val)
    },
    closeTooltip() {
      this.$emit('closeTooltip')
    },
  },
};
</script>
<style lang="scss" scoped>

.tree-view{
  font-size: rem(15px);

  &__list {
    list-style: none;

    &--child {
      margin-left: rem(-10px);
    }
  }

  &__list-item {
    padding-left: rem(30px);
    position: relative;

    &:before {
      content: '';
      width: rem(20px);
      height: rem(20px);
      border-radius: rem(10px);
      background-color: var(--bg-light-blue);
      position: absolute;
      top: 0;
      left: 0;
    }

    &--no-child {
      /*padding-left: 15px;*/
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: rem(10px);
  }

  &__item-label {
    position: relative;

    .active & {
      font-weight: 500;
      color: #1871C3;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    width: rem(20px);
    height: rem(20px);
    border-radius: rem(10px);
    position: absolute;
    left: 0;
    top: 0;

    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }
}

.draggable {
  cursor: move;
}

.draggable.dragging {
  opacity: 0.4;
}

[draggable] {
  user-select: none;
}
</style>