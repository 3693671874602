<template>
  <form class="main-search">
    <!-- dynamic components loader -->
    <component
        v-if="customers[customerId].searchModule"
        v-bind:customer-id="customerId"
        v-bind:data-handler="dataHandler"
        v-bind:is="customers[customerId].searchModule"
    />
    <div v-else>
      Component not found
    </div>
  </form>
</template>


<script>
import customers from '@/config/customers';
/**
 * custom search components must be registered here
 * and in the components object
 */
import DemoSearch from './custom/search/DemoSearch';
import OnvistaSearch from './custom/search/OnvistaSearch';
import CodevertiseSearch from './custom/search/CodevertiseSearch';
import HochzeitsrauschSearch from './custom/search/HochzeitsrauschSearch';

export default {
  name: 'MainSearch',
  components: {
    DemoSearch,
    OnvistaSearch,
    CodevertiseSearch,
    HochzeitsrauschSearch
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      customers,
    }
  },
}
</script>

<style lang="scss" scoped>
.main-search {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  font-size: rem(15px);
  margin-top: rem(20px);
}

</style>