<template>
  <p/>
</template>

<script>

export default {
  name: 'HochzeitsrauschSearch',
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
  },
  methods: {
  },
}
</script>