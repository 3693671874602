<template>
  <aside class="sidebar" v-bind:class="[{ '-hidden': sidebarHidden }, { '-right' : sidebarRight }]" v-bind:style="dragStyleCondition" ref="resize-container">
    <button
        v-if="allowToggle"
        class="sidebar__button"
        @click="toggleSidebar()"
    >
      <Icon name="chevron-up" css-class="-toggle" />
    </button>
    <button
        v-if="allowDrag && sidebarRight"
        class="sidebar__button -drag"
        v-bind:class="{'-grabbing': dragging}"
        v-on:mousedown="initDrag"
    >
      <span class="icon -output-toggle">&#x2630;</span>
    </button>
    <div class="sidebar__header" v-if="hasHeaderSlot" v-show="!sidebarHidden && !contentHidden">
      <slot name="header"></slot>
    </div>
    <div class="sidebar__content" v-show="!sidebarHidden && !contentHidden">
      <slot name="body"></slot>
    </div>
    <div class="sidebar__footer" v-if="hasFooterSlot" v-show="!sidebarHidden && !contentHidden">
      <slot name="footer"></slot>
    </div>
  </aside>
</template>

<script>
import Icon from '@/components/generic/Icon';

export default {
  name: 'sidebar',
  components: {Icon},
  data() {
    return {
      sidebarHidden: false,
      resultMaxWidth: 450,
      dragging: false,
      contentHidden: false,
    };
  },
  props: {
    allowToggle: {
      type: Boolean,
      default: false,
    },
    allowDrag: {
      type: Boolean,
      default: false,
    },
    sidebarRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    dragStyleCondition() {
      if (!this.allowDrag) {
        return null;
      } else {
        return `width: ${this.resultMaxWidth}px`
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarHidden = !this.sidebarHidden;
    },
    initDrag() {
      this.dragging = true;
      document.addEventListener('mouseup', this.stopDrag, false);
      document.addEventListener('mousemove', this.doDrag, false);
    },
    doDrag(event) {
      const resizeContainer = this.$refs['resize-container'];
      const viewportOffset = resizeContainer.getBoundingClientRect();
      this.resultMaxWidth = this.resultMaxWidth - (event.clientX - viewportOffset.left);
      this.contentHidden = this.resultMaxWidth < 300;
    },
    stopDrag() {
      this.dragging = false;
      document.removeEventListener('mouseup', this.stopDrag, false);
      document.removeEventListener('mousemove', this.doDrag, false);
    },
  },
}
</script>

<style lang="scss" scoped>

.sidebar {
  width: 100%;
  max-width: rem(300px);
  min-width: rem(300px);
  padding: rem(20px) rem(25px);
  box-shadow: rem(2px) rem(2px) rem(25px) 0 var(--box-shadow);
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.-hidden {
    width: rem(40px);
    min-width: rem(40px);
  }

  &.-right {
    min-width: auto;
    max-width: none;
    display: block;
    width: 450px;
    padding: rem(20px);
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: rem(20px);
    margin-bottom: rem(20px);
    border-bottom: 2px solid var(--border-light-1);
  }

  &__content {
    width: 100%;
    height: 100%;
    font-weight: 400;
    overflow-y: scroll;
    overflow-x: visible;
  }

  &__footer {
    width: 100%;
    font-weight: 500;
    z-index: 1;
    margin-top: auto;
    padding: rem(40px);
    border-top: 2px solid var(--border-light-1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    position: absolute;
    top: 40%;
    bottom: 60%;
    right: 0;
    border-radius: rem(15px) 0 0 rem(15px);
    height: rem(100px);
    padding: 0 rem(3px);
    background-color: var(--border-light-1);
    box-shadow: rem(2px) rem(2px) rem(25px) 0 var(--box-shadow);

    &.-drag {
      left: 0;
      right: auto;
      border-radius: 0 rem(15px) rem(15px) 0;
      cursor: grab;

      &.-grabbing {
        cursor: grabbing;
      }

    }
  }
}

</style>