<template>
  <form class="workspace__form">
    <input
        type="text"
        list="list"
        v-model="searchQuery"
        v-on:keyup="search"
        v-on:change="addAnimalToList"
        v-bind:placeholder="animalPlaceholder"
    >
    <datalist id="list">
      <option
          v-for="(result, index) in searchList"
          v-bind:key="index"
      >
        {{ result }}
      </option>
    </datalist>
  </form>
</template>

<script>
import simpleDebounce from '@/classes/debounce-mixin';

export default {
  name: 'DemoSearch',
  props: {
    customerId: {
      type: String,
      required: true,
    },
    dataHandler: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      searchQuery: '',
      searchList: [],
    }
  },
  mixins: [ simpleDebounce, ],
  computed: {
    workspaceData() {
      return this.dataHandler.getData();
    },
    animalPlaceholder() {
      return `${this.workspaceData.animals[0]? this.workspaceData.animals[0] : 'Name des Haustiers' }`
    }
  },
  methods: {
    addAnimalToList(event) {
      if (event.target.value) {
        this.dataHandler.addAnimalToList(event.target.value);
        this.searchList = [];
      }
    },
    async search() {
      this.simpleDebounce(async () => {
        this.searchList = await this.dataHandler.search(this.searchQuery);
      }, 200)
    },
  },
}
</script>