<template>
  <teleport to="#header">
    <nav class="template-nav">
      <button class="template-nav__item -back" v-on:click="$router.go(-1)" v-if="$route.name !== 'Organizer'">
        <Icon name="arrow-left" />
        <span>Zurück</span>
      </button>

      <router-link class="template-nav__item" v-bind:to="{name: 'TextOverview'}">
        Textübersicht
      </router-link>
      <button class="template-nav__item -right">
        <span>Stories</span>
        <div class="dropdown">
          <button class="dropdown__item" @click="emitNewTemplate">
            Neue Story
          </button>
  <!--        <router-link class="dropdown__item" v-bind:to="{name: 'Templates'}">
            Story Übersicht
          </router-link>-->
          <button class="dropdown__item">
            <label for="file-upload">
              Öffnen
              <input type="file" id="file-upload" accept="application/JSON" @change="loadTemplateFile">
            </label>
          </button>
          <button class="dropdown__item" @click="emitSave">
            Speichern
          </button>
          <router-link  class="dropdown__item" v-bind:to="{name: 'Settings'}">
            <span>Einstellungen</span>
          </router-link>
        </div>
        <Icon name="chevron-up" css-class="template-nav__icon" />
      </button>
      <button class="template-nav__item">
        <Icon name="settings" />
        <span>Account</span>
        <div class="dropdown">
          <router-link class="dropdown__item" v-bind:to="{name: 'Account'}">
            Einstellungen
          </router-link>
          <span class="dropdown__item -border-top" v-on:click="logout">
            <Icon name="logout"/>&nbsp;Abmelden
          </span>
        </div>
        <Icon name="chevron-up" css-class="template-nav__icon" />
      </button>
      <div id="navigation-feedback-button"></div>
    </nav>
  </teleport>
</template>

<script>
import Icon from '@/components/generic/Icon';

export default {
  name: "TemplateNavigation",
  emits: ['save', 'new-template', 'file'],
  components: {Icon},
  data() {
    return {
      showFeedback: false,
      json: {},
    }
  },
  methods: {
    async logout() {
      const response = await fetch('/api/v1/account/logout', {
        method: 'POST'
      });
      if (response.status === 200) {
        this.$router.push({name: 'Login'});
      } else {
        console.error('Logout request failed')
      }
    },
    emitSave() {
      this.$emit('save', true)
    },
    emitNewTemplate() {
      this.$emit('new-template', true)
    },
    loadTemplateFile(event) {
      let files = event.target.files;
      let file = files[0];
      let reader = new FileReader();
      reader.addEventListener('load', () => {
        this.json = JSON.parse(reader.result);
        this.$emit('file', this.json);
      });
      reader.readAsText(file);
      event.target.value = '';
    }
  },
}
</script>

<style lang="scss" scoped>

.template-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $text-color-light;
  height: 100%;
  position: relative;
  flex-grow: 1;
  padding: 0 rem(60px) 0 rem(170px);

  &__item {
    font-size: inherit;
    color: inherit;
    outline: none;
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    position: relative;
    margin-right: rem(50px);

    &:hover,
    &:focus,
    &:focus-within {
      text-decoration: underline;

      .dropdown {
        display: block;
      }

      > .template-nav__icon {
        transform: rotate(180deg);
      }
    }

    &.-back {
      position: absolute;
      left: rem(50px);
    }

    &.-right {
      margin-left: auto;
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      margin: 0 rem(8px);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__icon {
    margin-left: rem(8px);
  }
}

.dropdown {
  display: none;
  position: absolute;
  z-index: 2;
  width: rem(250px);
  padding: rem(10px) 0;
  top: rem(60px);
  border-radius: rem(5px);
  border-top: 1px solid var(--bg-dark-2);
  left: 0;
  background-color: var(--bg-dark-1);
  box-shadow: 0px rem(4px) rem(10px) rgba(0, 0, 0, 0.25);

  &__item {
    color: inherit;
    display: block;
    font-size: inherit;
    text-decoration: none;
    text-align: left;
    background-color: transparent;
    padding: rem(10px) rem(20px);

    &:focus,
    &:hover {
      text-decoration: underline;
    }

    input {
      display: none;
    }

    &.-border-top {
      padding-top: rem(20px);
      border-top: 1px solid $nav-border-color;
    }
  }
}

.router-link-active {
  color: var(--border-light-blue);
  text-decoration: underline;
  pointer-events: none;
  cursor: default;
}

#navigation-feedback-button {
  white-space: nowrap;
}

</style>